.videoOptionsViewWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 30px;
  overflow: auto;
  /*color: #ED3952;*/
  /*color: #5A2DEA;*/
}

.videoOptionsItemWrapper {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.videoOptionsItem {
  padding: 10px;
  background-color: rgb(127, 74, 246, .6);
  color: #ffffff;
  font-size: 13px;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  transform:scale(1);
  transition:transform .5s;
}

.videoOptionsItem:hover {
  background-color: rgb(127, 74, 246, .8);
  transform:scale(1.03);
  transition:transform .5s;
}
