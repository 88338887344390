.formWrapper{
    height: calc(100% - 30px);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formTitle {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .4px;
    line-height: 140%;
    margin-bottom: 25px;
}

.bottomMargin {
    margin-bottom: 20px !important;
    width: 100%;
}

.selectBox {
    width: 100%
}

.formSubmitBtn {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    background-color: #4094d2;
    color: #ffffff;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    transform: scale(1);
    transition: transform .5s;
    border: none;
    margin-bottom: 20px;
}

.formSubmitBtn:hover {
    background-color: #4aa6e7;
    transform: scale(1.03);
    transition: transform .5s;
}
