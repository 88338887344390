.container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 20000;
}

.introPopup {
    position: relative;
    width: 130px;
    height: 130px;
    border: 6px solid rgb(82, 44, 187);
    border-radius: 50%;
    background-size: contain;
    overflow: hidden;
    transform: scale(1);
    transition: transform 0.5s;
    background-color: #522cbbb8;
}

.introPopup:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
}

.videoView {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
}

.textSection {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    font-size: 12px;
    font-weight: 700;
    background-color: rgba(0, 0, 0, .2);
    color: white;
}
