.videoViewWrapper {
    width: 250px;
    height: 450px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 20000;
}

.videoViewWrapperFullScreen {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 20000;
}

.container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.bgBlack {
    background-color: black;
}

.waterMark {
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 4px 0;
    color: white;
    background: rgba(0, 0, 0, 0.3);
    transition: background 0.1s;
    font-size: 12px;
    text-decoration: none;
}

.fullScreenWaterMark {
    width: auto;
    left: 20px;
    bottom: 43px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 30px;
    padding-left: 10px;
    border-radius: 5px;
}

.waterMark:hover {
    transition: background 0.1s;
    background: rgba(0, 0, 0, 0.8);
}

.brandName {
    font-size: 15px;
    font-weight: 600;
}

.playBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    font-size: 2.5rem !important;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.5s;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 8px;
}

.playBtn:hover {
    transform: translate(-50%, -50%) scale(1.1);
    transition: transform 0.5s;
}

.controlsWrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
}

.timeLine {
    font-weight: 500;
    font-size: 13px;
}

.playBackSpeed {
    padding: 0 5px;
    font-weight: 500;
    font-size: 12px;
    border: 3px solid white;
    border-radius: 5px;
    text-align: center;
    margin: 0 5px 0 10px;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.1s;
}

.playBackSpeed:hover {
    transform: scale(1.1);
    transition: transform 0.1s;
}

.speedIncreased {
    background-color: white;
    color: black;
}

.fullScreenBtn {
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.1s;
}

.fullScreenBtn:hover {
    transform: scale(1.1);
    transition: transform 0.1s;
}

.videoProgressBar {
    position: absolute;
    color: #3794ff;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.3);
    vertical-align: middle;
    z-index: 1;
    width: 100%;
    transform: translateY(-5px);
    transition: transform 0.5s;
    cursor: pointer;
}

.videoProgressBar:hover {
    transform: translateY(0px);
    transition: transform 0.5s;
}

.videoProgressBar::-moz-progress-bar {
    color: #3794ff;
    background: rgba(255, 255, 255, 0.3);
}

.videoProgressBar[value]::-webkit-progress-bar {
    background-color: rgba(255, 255, 255, 0.3);
}

.videoProgressBar[value]::-webkit-progress-value {
    background-color: #3794ff;
}

.videoView {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
}

.fullVideoView {
    object-fit: contain;
}

video::-webkit-media-controls-enclosure {
    display: none !important;
}

.videoCloseButton {
    position: absolute;
    z-index: 2;
    color: white;
    right: -10px;
    top: -10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    padding: 5px;
    transform: rotate(-90deg);
    transition: transform 0.5s;
}

.hideCloseIcon {
    display: none;
}

.videoCloseButton:hover {
    transform: rotate(0deg);
    transition: transform 0.5s;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.optionWrapper {
    position: absolute;
    bottom: 0;
    margin-bottom: 35px;
    width: 100%;
}

/* loader component styles */
.loaderContainer {
    position: relative;
    /* padding: 20px; */
    border-radius: 50%;
    background-image: linear-gradient(#7F4AF6, rgb(96, 49, 236));
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    font-size: 11px;
    line-height: 12px;
}

.loaderContainer::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    transform: scale(1.04);
    width: 100%;
    height: 100%;
    background: transparent;
    border-right: 3px solid #6c05f2;
    box-shadow: 0px 0px 20px 10px rgb(0 0 0 / 10%);
    animation: spin 1s linear infinite;
}

.companyName {
    font-weight: 500;
    font-size: 13px;
}

.createdBrand {
    margin-top: 5px;
    font-weight: 750;
    font-size: 15px;
}

@keyframes spin {
    from {
        transform: scale(1.04) rotate(0deg);
    }
    to {
        transform: scale(1.04) rotate(360deg);
    }
}

/* success snackbar css */

.snakContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30000;
}

.card {
    width: 20em;
    padding: 15px;
}

.snackIcon {
    font-size: 70px !important;
}

.textMsg {
    font-size: 23px;
    font-weight: 500;
}

.centerText {
    text-align: center;
}

